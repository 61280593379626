<template>
  <div>
    <h2>プロフィール</h2>
    <b-card
      title=""
      :img-src="img"
    >
      <div
        v-for="(item,index) in menulist"
        :key="index"
        :class="index ? 'mt-2':''"
      >
        <b-row>
          <b-col lg="4">
            <h5
              class="text-capitalize mb-75"
            >
              {{ item.label }}
            </h5>
          </b-col>
          <b-col>
            <b-card-text
              v-if="item.key === 'learningCategory'"
            >
              <div
                v-for="(it, key, id) in profileDic[item.key]"
                :key="id"
                class="mt-1"
              >
                <div class="">
                  {{ key }}
                </div>
                <span
                  v-for="(i, idd) in show(key, it)"
                  :key="idd"
                  class="mr-1"
                >
                  {{ i.lessonLabel }}
                </span>
              </div>
            </b-card-text>
            <b-card-text
              v-else-if="item.type === 'selectSingle'"
            >
              <div
                v-if="profileDic[item.key]"
              >
                {{ profileDic[item.key]['labelName'] }}
              </div>
            </b-card-text>
            <b-card-text
              v-else
            >
              <div
                v-if="profileDic[item.key]"
              >
                {{ profileDic[item.key] }}
              </div>
              <div
                v-else
              >
                登録なし
              </div>
            </b-card-text>
          </b-col>
        </b-row>
      </div>
      <b-row>
        <b-col offset-md="8">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2"
            @click="edit"
          >
            編集する
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol, BButton, BCard, BCardText,
} from 'bootstrap-vue'
/* eslint-disable */
import menulist from '@/components/conf/JPCAREER様_学習PF_受講生_プロフィール_20210921.json'
import choiceList from '@/components/conf/JPCAREER様_学習PF_選択肢.json'
/* eslint-enable */

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BCardText,
  },
  directives: {
    Ripple,
  },
  props: {
    profileDic: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      menulist,
      choiceList,
      img: 'https://firebasestorage.googleapis.com/v0/b/jp-career.appspot.com/o/articles%2Fleone-venter-VieM9BdZKFo-unsplash.jpg?alt=media&token=6e196ec3-af0b-43d3-b828-404dfd1badaa',
    }
  },
  methods: {
    edit() {
      this.$router.push({ path: 'student-profile-edit' })
    },
    show(key, val) {
      const labelList = this.choiceList.filter(v => v.category === key)
      const newList = labelList.filter(v => val.indexOf(v.num) !== -1)
      // window.console.log('🏎✨️', newList, val)
      return newList
    },
  },
}
</script>
