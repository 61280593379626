<template>
  <div
    slot="sidebar"
    class="blog-sidebar py-2 py-lg-0"
  >
    <div class="blog-recent-posts mt-2">
      <h6 class="section-label mb-75">
        講師用記事(仮題)
      </h6>
      <b-media
        v-for="(recentpost,index) in blogSidebar.recentPosts"
        :key="recentpost.img"
        no-body
        :class="index? 'mt-2':''"
      >
        <b-media-aside class="mr-2">
          <!-- <b-link :to="{ name: 'pages-blog-detail', params:{ id :recentpost.id } }"> -->
          <b-img
            :src="recentpost.img"
            :alt="recentpost.img.slice(6)"
            width="100"
            rounded
            height="70"
          />
          <!-- </b-link> -->
        </b-media-aside>
        <b-media-body>
          <h6 class="blog-recent-post-title">
            <!-- <b-link
              :to="{ name: 'pages-blog-detail', params:{ id :recentpost.id } }"
              class="text-body-heading"
            > -->
            {{ recentpost.title }}
            <!-- </b-link> -->
          </h6>
          <span class="text-muted mb-0">
            {{ recentpost.createdTime }}
          </span>
        </b-media-body>
      </b-media>
    </div>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BMedia, BMediaAside,
  // BLink,
  BImg, BMediaBody,
} from 'bootstrap-vue'
/* eslint-disable */
import menulist from '@/components/conf/JPCAREER様_学習PF_講師_プロフィール.json'
import choiceList from '@/components/conf/JPCAREER様_学習PF_選択肢.json'
/* eslint-enable */
// import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue'

export default {
  components: {
    // BRow,
    // BCol,
    // BButton,
    // BCard,
    // BCardText,
    // BAlert,
    BMedia,
    BMediaAside,
    // BLink,
    BImg,
    BMediaBody,
    // ContentWithSidebar,
  },
  directives: {
    Ripple,
  },
  props: {
    profileDic: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      menulist,
      choiceList,
      blogSidebar: {},
    }
  },
  created() {
    this.$http.get('/blog/list/data').then(res => { this.blogList = res.data })
    this.$http.get('/blog/list/data/sidebar').then(res => { this.blogSidebar = res.data })
  },
  methods: {
    edit() {
      this.$router.push({ path: 'teacher-profile-edit' })
    },
    show(key, val) {
      const labelList = this.choiceList.filter(v => v.category === key)
      const newList = labelList.filter(v => val.indexOf(v.num) !== -1)
      // window.console.log('🏎✨️', newList, val)
      return newList
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
</style>
